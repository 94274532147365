const film_images = [
    { path: '/assets/images/film_images/film_1.jpg', possition: "gallery__image--3" },
    { path: '/assets/images/film_images/film_2.jpg', possition: "gallery__image--2" },
    { path: '/assets/images/film_images/film_3.jpg', possition: "gallery__image--1" },
    { path: '/assets/images/film_images/film_4.jpg', possition: "gallery__image--5" },
    { path: '/assets/images/film_images/film_5.jpg', possition: "gallery__image--4" },
    { path: '/assets/images/film_images/film_6.jpg', possition: "gallery__image--1" },
    { path: '/assets/images/film_images/film_7.jpg', possition: "gallery__image--2" },
    { path: '/assets/images/film_images/film_8.jpg', possition: "gallery__image--5" },
    { path: '/assets/images/film_images/film_9.jpg', possition: "gallery__image--3" },
    { path: '/assets/images/film_images/film_10.jpg', possition: "gallery__image--5" },
    { path: '/assets/images/film_images/film_11.jpg', possition: "gallery__image--4" },
    { path: '/assets/images/film_images/film_12.jpg', possition: "gallery__image--1" },
    { path: '/assets/images/film_images/film_13.jpg', possition: "gallery__image--5" },
    { path: '/assets/images/film_images/film_14.jpg', possition: "gallery__image--2" },
    { path: '/assets/images/film_images/film_15.jpg', possition: "gallery__image--1" },
    { path: '/assets/images/film_images/film_16.jpg', possition: "gallery__image--5" },
    { path: '/assets/images/film_images/film_17.jpg', possition: "gallery__image--3" },
    { path: '/assets/images/film_images/film_18.jpg', possition: "gallery__image--2" },
    { path: '/assets/images/film_images/film_19.jpg', possition: "gallery__image--4" },
    { path: '/assets/images/film_images/film_20.jpg', possition: "gallery__image--5" },
];

export default film_images;
