import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MiniProject = ({ menu_projects }) => (
    <div className="project">
        <a alt={menu_projects.title} onClick={() => { window.location.href = `/project/${menu_projects.id}` }}>

            <LazyLoadImage
                width="350"
                height="231"
                src={menu_projects.image}
                className="attachment-small size-small wp-post-image entered lazyloaded"
                alt=""
            />
            <div className="show__logo"></div>
            <div className="overlay__bg "></div>
            <h3 className="project__title">{menu_projects.title}</h3>
        </a>
    </div>
);

export default MiniProject;
