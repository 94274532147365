import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import dayjs from 'dayjs';

const PictureInProject = ({ menu_projects }) => (
    <a
        href={menu_projects.path}
        data-index="4" rel="gallery"
        className={`photo swipebox ${menu_projects.possition} landscape`}>

        <LazyLoadImage
            width="714"
            height="1080"
            src={menu_projects.path}
            className={`gallery__path--${menu_projects.position} portrait entered lazyloaded`}
            alt=""
        />

        <noscript>
            <LazyLoadImage
                width="714"
                height="1080"
                src={menu_projects.path}
                className={`photo gallery__image--${menu_projects.position} about_portrait`}
                alt=""
            />
        </noscript>

    </a>
);

export default PictureInProject;
