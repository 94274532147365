import React from 'react';
// import dayjs from 'dayjs';

const Footer = ({ data }) => (

    <footer>
        <div className="footer__inner">
            © 2022 JAKUB BREHUV ALL RIGHTS RESERVED
        </div>
    </footer>



);

export default Footer;
