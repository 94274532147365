import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import dayjs from 'dayjs';

const PictureInContact = ({ picture }) => (
    [<LazyLoadImage
        width="430"
        height="430"
        src={picture.path}
        className={`photo ${picture.possition} about_portrait entered lazyloaded`}

        alt=""
    />
        ,
    <noscript>
        <LazyLoadImage
            width="430"
            height="430"
            src={picture.path}
            className={`photo ${picture.possition} about_portrait`}
            alt=""
        />
    </noscript>]
);

export default PictureInContact;
