import React from "react"
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SlidingText from "../components/SlidingText";

//projects import

export default function DataProtection() {

    return (
        <React.Fragment>
            <Navbar />
            <section className="project__footer marquee end" id="scroll__area">
                <SlidingText text={'DataProtection'} />
            </section>
            <Footer />
        </React.Fragment>
    );
}




