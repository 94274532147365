import React from 'react';
import { Link } from 'react-router-dom';
// import dayjs from 'dayjs';

const Navbar = ({ data }) => (
    <header id="header">
        <nav>
            <ul>
                <div className="left_list">
                    <li>
                        {/* <Link to="/">JAKUB BREHUV</Link> */}
                        <a onClick={() => { window.location.href = `/` }}>
                            JAKUB BREHUV</a>

                    </li>
                </div>
                <li>
                    <div id="menu-toggle">
                        {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60.24"
                            height="60.607"
                            viewBox="0 0 60.24 60.607"
                        >
                            <path
                                d="M58.19,83.407H73.25L70.311,61.368,88.126,74.959,95.84,61.735,75.27,53.1l20.57-8.632L88.126,31.248,70.311,44.839,73.25,22.8H58.19l2.939,22.039L43.314,31.248,35.6,44.472,56.17,53.1,35.6,61.735l7.714,13.223L61.128,61.368Z"
                                transform="translate(-35.6 -22.8)"
                                fill="#cf092d"
                            ></path>
                        </svg> */}

                        <svg xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24"
                            fill="#cf092d" class="bi bi-circle-fill" viewBox="0 0 16 16"> <circle cx="8" cy="8" r="8" />
                        </svg>
                    </div>
                </li>
                <div className="right_list">
                    <li>
                        <a href="/contact">Contact</a>
                    </li>
                </div>
            </ul>
        </nav>

        <nav className="nav--wrapper">
            <ul>
                <li>
                    {/* todo: learn hooks on this */}
                    <a href="/contact" className="contact" title="Contact">
                        contact
                    </a>
                </li>

                {/* <li>
                    <a
                        href="/film"
                        className="tagebuch"
                        title="film"
                    >
                        film
                    </a>
                </li>

                <li>
                    <a
                        href="/random"
                        className="tagebuch"
                        title="random"
                    >
                        random
                    </a>
                </li> */}

                <li>
                    <a
                        href="https://www.instagram.com/jakubbrehuv/"
                        target="_blank"
                        title="Instagram"
                    >
                        instagram
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.behance.net/jakubbrehuv"
                        target="_blank"
                        title="Behance"
                    >
                        behance
                    </a>
                </li>
                {/* <li className="show-mobile ">
                    <a
                        href="/data-protection"
                        title="Data protection"
                    >
                        Data protection
                    </a>
                </li> */}
            </ul>
            <p>
                JAKUB BREHUV
            </p>
        </nav>
    </header>
);

export default Navbar;
