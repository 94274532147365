import React from "react"
import { useEffect } from 'react';
// import { Link } from "react-router-dom"
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SlidingText from "../components/SlidingText";

//projects import

export default function NotFound() {

    return (
        <React.Fragment>
            <Navbar />

            <section className="project__footer marquee end" id="scroll__area">
                <SlidingText text={'NOT FOUND'} />
            </section>
            <Footer />

        </React.Fragment>
    );
}




