import React from "react"
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PictureInProject from "../components/PictureInProject";
import ProjectRecomendation from "../components/ProjectRecomendation";


//projects import
import menu_projects from '../data/menu_projects';

export default function Project() {
    const { id } = useParams();

    //TODO: limit project pages

    var index = menu_projects.findIndex(function (item, i) {
        return item.id === id
    });

    var found = menu_projects[index];

    return (
        <React.Fragment>
            <Navbar />
            <div className="project__body" >

                <article className="site__main" id="main-wrapper">
                    <section className="project__container" id="container__top">
                        <div className="project__inner target_area">
                            <div className="project__top">
                                <div className="headline__arrow">
                                    <h2 className="project__headline">{found.title}</h2>
                                    <div className="picture__counter">
                                        {/* <div className="counter__inner start" style=""> */}
                                        {/* <div className="counter__inner start">
                                            <span className="count">01 / </span>
                                            <span className="total_num"> 7 </span>
                                        </div> */}
                                    </div>
                                </div>
                                <figure className="top__img">
                                    <a href={found.image}
                                        data-index="1"
                                        rel="gallery"
                                        className="photo swipebox">
                                        <img
                                            width="1080"
                                            height="720"
                                            src={found.image}
                                            className="top_image--landscape entered lazyloaded"
                                            alt=""
                                            data-lazy-srcset={`${found.image} 1080w, ${found.image} 700w, ${found.image} 768w, ${found.image} 350w, ${found.image} 800w, ${found.image} 430w`}
                                            data-lazy-sizes="(max-width: 1080px) 100vw, 1080px"
                                            data-lazy-src={found.image}
                                            data-ll-status="loaded" sizes="(max-width: 1080px) 100vw, 1080px"
                                            srcSet={`${found.image} 1080w, ${found.image} 700w, ${found.image} 768w, ${found.image} 350w, ${found.image} 800w, ${found.image} 430w`} />
                                        <noscript>
                                            <img
                                                width="1080"
                                                height="720"
                                                src={found.image}
                                                className="top_image--landscape"
                                                alt=""
                                                srcSet={`${found.image} 1080w, ${found.image} 700w, ${found.image} 768w, ${found.image} 350w, ${found.image} 800w, ${found.image} 430w`}
                                                sizes="(max-width: 1080px) 100vw, 1080px" /></noscript>
                                    </a>
                                </figure>
                            </div>

                            <div className="post__content end">
                                <p className="heart"></p>
                                <p><span className="label">{found.clientHead}</span>{found.client}</p>
                                <p>{found.info}</p>
                                <a href={found.link}>{found.link}</a>
                            </div>

                            <div className="photos__wrapper" id="scroll__target">

                                {found.pictures.map((pic) => (
                                    <PictureInProject
                                        menu_projects={pic}
                                    />
                                ))}

                            </div>

                            <div className="post__content end">
                                <p className="heart"></p>
                                <p><span className="label">{found.clientHead}</span>{found.client}</p>
                                <p>{found.info}</p>
                                <a href={found.link}>{found.link}</a>
                            </div>


                            {/* <div className="post__content end">
                                <p className="heart"></p>
                                <p><span className="label">Client</span>{'\u00A0'};{'\u00A0'};{'\u00A0'}; Katjes</p>
                                <p><span className="label">Production</span>{'\u00A0'};{'\u00A0'};{'\u00A0'}; Le Berg</p>
                                <p>Director: Jen Krause</p>
                                <p>DOP: Nicola Rehbein</p>
                                <p><span className="label">Agency</span>{'\u00A0'};{'\u00A0'};{'\u00A0'}; Antoni Berlin</p>
                            </div> */}

                        </div>

                        <ProjectRecomendation />

                    </section>
                </article>
            </div>




            <Footer />
        </React.Fragment>
    );
}