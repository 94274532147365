import React from 'react';

const SlidingText = ({ text }) => (
    <h2 className="uppercase marquee__inner">
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
        <span className="marquee-txt">{text}</span>
    </h2>
);

export default SlidingText;
