const contact_images = [
    { path: '/assets/images/contact_images/min/R1-05189-0019.jpg', possition: "gallery__image--1" },
    { path: '/assets/images/contact_images/min/R1-05189-0036.jpg', possition: "gallery__image--4" },
    { path: '/assets/images/contact_images/min/R1-05191-034A.jpg', possition: "gallery__image--3" },
    { path: '/assets/images/contact_images/min/IMG_3429.jpg', possition: "gallery__image--1" },
    // { path: '/assets/images/contact_images/min/IMG_3331.jpg', possition: "gallery__image--1" },
    { path: '/assets/images/contact_images/min/IMG_3076.jpg', possition: "gallery__image--6" },
    { path: '/assets/images/contact_images/min/IMG_3073.jpg', possition: "gallery__image--1" },
    // { path: '/assets/images/contact_images/min/IMG_3003.jpg', possition: "gallery__image--1" },

];

export default contact_images;


