const random_images = [
    { path: '/assets/images/random_images/film_1.jpg' },
    { path: '/assets/images/random_images/film_2.jpg' },
    { path: '/assets/images/random_images/film_3.jpg' },
    { path: '/assets/images/random_images/film_4.jpg' },
    { path: '/assets/images/random_images/film_5.jpg' },
    { path: '/assets/images/random_images/film_6.jpg' },
    { path: '/assets/images/random_images/film_7.jpg' },
    { path: '/assets/images/random_images/film_8.jpg' },
    { path: '/assets/images/random_images/film_9.jpg' },
    { path: '/assets/images/random_images/film_10.jpg' },
    { path: '/assets/images/random_images/film_11.jpg' },
    { path: '/assets/images/random_images/film_12.jpg' },
    { path: '/assets/images/random_images/film_13.jpg' },
    { path: '/assets/images/random_images/film_14.jpg' },
    { path: '/assets/images/random_images/film_15.jpg' },
    { path: '/assets/images/random_images/film_16.jpg' },
    { path: '/assets/images/random_images/film_17.jpg' },
    { path: '/assets/images/random_images/film_18.jpg' },
    { path: '/assets/images/random_images/film_19.jpg' },
    { path: '/assets/images/random_images/film_20.jpg' },
]

export default random_images;


