import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';


// import dayjs from 'dayjs';
function getWindowWidth() {
    const { innerWidth: width } = window;
    return {
        width
    };
}

function showImage(data) {
    if (getWindowWidth().width >= 1024) {
        return (
            <img
                width="400"
                height="300"
                src={data.image}
                className=""
                alt=""
            />)
    } else {
        return (

            <LazyLoadImage
                width="400"
                height="300"
                src={data.image}
                alt=""
            />)
    }
}

const ProjectInMenu = ({ menu_projects }) => (

    <div className="work">
        <div className="work__title visible">
            <h2>
                <a
                    onClick={() => { window.location.href = `/project/${menu_projects.id}` }}
                    className="link">
                    {menu_projects.title}
                </a>
            </h2>
        </div>

        <a onClick={() => { window.location.href = `/project/${menu_projects.id}` }} className="work__image__wrapper">
            <div className="work__image__inner">
                <figure className="trim_wrap">
                    {/* <span>11 images</span> */}
                    {showImage(menu_projects)}
                </figure>
            </div>
        </a>
    </div>


);

ProjectInMenu.propTypes = {
    menu_projects: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        imageWidth: PropTypes.string.isRequired,
        imageHeight: PropTypes.string.isRequired,
        imagesCount: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        info: PropTypes.string.isRequired,
        clientHead: PropTypes.string.isRequired,
        client: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        pictures: PropTypes.shape({
            path: PropTypes.string.isRequired,
            possition: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default ProjectInMenu;
