//todo: work on gif image in menu (video)


// e4383b
// 191919

const data = [
    {
        id: '22',
        title: 'BMX ZILINA 2022',
        subtitle: 'BMX ZILINA 2022',
        src: "",
        image: '/assets/images/bmx_za_2022/min/_46.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "97",
        date: '2022-05-03',
        desc: '',
        clientHead: 'Ahojte, dalsie fotky najdete este tuna na linku :)',
        client: '',
        link: 'https://drive.google.com/drive/folders/1e4_xA2mw4z5Cte80nAqJgYp8_8q3LzD7?usp=sharing',
        color: 'fff',
        pictures: [
            { path: '/assets/images/bmx_za_2022/min/_50.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_51.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_52.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_53.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_54.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_55.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_56.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_57.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_58.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_59.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_60.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_61.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_62.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_63.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_64.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_65.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_66.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_67.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_68.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_69.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_70.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_71.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_72.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_73.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_74.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_75.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_76.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_77.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_78.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_79.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_80.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_81.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_82.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_83.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_84.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_85.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_86.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_87.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_88.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_89.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_91.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_92.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_93.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_94.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_95.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_96.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_90.jpg', possition: "gallery__image--2" },

            { path: '/assets/images/bmx_za_2022/min/_97.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_1.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_2.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_3.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_4.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_5.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_6.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_7.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_8.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_9.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_10.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_11.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_12.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_13.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_14.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_15.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_16.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_17.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_18.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_19.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_20.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_21.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_22.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_23.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_24.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_25.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_26.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_27.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_28.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_29.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/bmx_za_2022/min/_30.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_31.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_32.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_33.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_34.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_35.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_36.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_37.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_38.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_39.jpg', possition: "gallery__image--2" },

            { path: '/assets/images/bmx_za_2022/min/_40.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_41.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_42.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/bmx_za_2022/min/_43.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_44.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/bmx_za_2022/min/_45.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_46.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/bmx_za_2022/min/_47.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/bmx_za_2022/min/_48.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/bmx_za_2022/min/_49.jpg', possition: "gallery__image--1" },

        ]
    },
    {
        id: '1',
        title: 'JOHNNY & SONA',
        subtitle: 'JOHNNY & SONA',
        src: "",
        image: '/assets/images/johnny_sona/min/_5.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "10",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/johnny_sona/min/_1.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_2.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/johnny_sona/min/_3.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_4.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_5.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_6.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_7.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/johnny_sona/min/_8.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/johnny_sona/min/_9.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_10.jpg', possition: "gallery__image--5" },

            { path: '/assets/images/johnny_sona/min/_11.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_12.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_13.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/johnny_sona/min/_14.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/johnny_sona/min/_15.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_16.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_17.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/johnny_sona/min/_18.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_19.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_20.jpg', possition: "gallery__image--4" },

            { path: '/assets/images/johnny_sona/min/_21.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_22.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_23.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_24.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_25.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/johnny_sona/min/_26.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_27.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/johnny_sona/min/_28.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_29.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_30.jpg', possition: "gallery__image--5" },

            { path: '/assets/images/johnny_sona/min/_31.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_32.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_33.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_34.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/johnny_sona/min/_35.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_36.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/johnny_sona/min/_37.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_38.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_39.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_40.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/johnny_sona/min/_41.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/johnny_sona/min/_42.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_43.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_44.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/johnny_sona/min/_45.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/johnny_sona/min/_46.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/johnny_sona/min/_47.jpg', possition: "gallery__image--2" },
        ]
    },
    {
        id: '2',
        title: 'SARA & ONDREJ SVADBA',
        subtitle: 'SARA & ONDREJ SVADBA',
        src: "",
        image: '/assets/images/sara_ondrej_svadba/min/_DSC6296.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "42",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6279.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6296.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6297.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6320.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6374.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6377.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6394.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6402.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6451.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6489.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6503.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6609.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6615.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6813.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC6861.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7055.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7062.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7103.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7151.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7270.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7324.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7382.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7630.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7657.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7757.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7761.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7815.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7828.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7840.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7908.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7980.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC7999.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8079.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8103.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8272.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8279.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8715.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8744.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8870.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/sara_ondrej_svadba/min/_DSC8816.jpg', possition: "gallery__image--3" },
        ]
    },
    {
        id: '3',
        title: 'ALLTIDE & RIJOLA SESSION',
        subtitle: 'ALLTIDE & RIJOLA SESSION',
        src: "",
        image: '/assets/images/alltide_rijola_sesh/min/_DSC9501.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "13",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9725.jpg', possition: "gallery__image--5" },

            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9501.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9507.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9715.jpg', possition: "gallery__image--2" },

            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9537.jpg', possition: "gallery__image--8" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9573.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9615.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9686.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9737.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9760.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9775.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_rijola_sesh/min/_DSC9745.jpg', possition: "gallery__image--2" },

        ]
    },

    {
        id: '4',
        title: 'MAREK & ALEX',
        subtitle: 'MAREK & ALEX',
        src: "",
        image: '/assets/images/alex/min/_DSC1493.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/alex/min/_DSC0789.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alex/min/_DSC0824.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC0837.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alex/min/_DSC0866.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alex/min/_DSC1004.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC1073.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alex/min/_DSC1157.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC1199.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alex/min/_DSC1355.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alex/min/_DSC1409.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC1426.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alex/min/_DSC1459.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alex/min/_DSC1493.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC1728.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alex/min/_DSC1769.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alex/min/_DSC1783.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alex/min/_DSC2032.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC2038.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alex/min/_DSC2089.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alex/min/_DSC2143.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC2197.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alex/min/_DSC2224.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC2244.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alex/min/_DSC2364.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC2393.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alex/min/_DSC2627.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alex/min/_DSC2699.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alex/min/_DSC2726.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alex/min/_DSC2896.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alex/min/_DSC2994.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alex/min/_DSC3058.jpg', possition: "gallery__image--0" },
        ]
    },

    {
        id: '5',
        title: 'DAVID & SANA',
        subtitle: 'DAVID & SANA',
        src: "",
        image: '/assets/images/david_sana/min/_DSC3260.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/david_sana/min/_DSC2748.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/david_sana/min/_DSC2940.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/david_sana/min/_DSC2997.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/david_sana/min/_DSC3241.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/david_sana/min/_DSC3260.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/david_sana/min/_DSC3534.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/david_sana/min/_DSC3563.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/david_sana/min/DSC00290.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/david_sana/min/DSC00313.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/david_sana/min/DSC00467.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/david_sana/min/DSC00475.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/david_sana/min/DSC00546.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/david_sana/min/DSC00559.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/david_sana/min/DSC00568.jpg', possition: "gallery__image--3" },
        ]
    },
    {
        id: '6',
        title: 'ISABEL IN SPISH',
        subtitle: 'ISABEL IN SPISH',
        src: "",
        image: '/assets/images/isabel/min/_DSC6064.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "12",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/isabel/min/_DSC5394.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/isabel/min/_DSC5491.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/isabel/min/_DSC5527.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/isabel/min/_DSC5980.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/isabel/min/_DSC6037.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/isabel/min/_DSC6057.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/isabel/min/_DSC6064.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/isabel/min/_DSC6220.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/isabel/min/_DSC6516.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/isabel/min/_DSC6583.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/isabel/min/_DSC6613.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/isabel/min/_DSC5488.jpg', possition: "gallery__image--1" },

        ]
    },
    {
        id: '7',
        title: 'RAVE IN KUBOS ROOM',
        subtitle: 'RAVE IN KUBOS ROOM',
        src: "",
        image: '/assets/images/kubo_rave/min/_DSC2551.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "12",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/kubo_rave/min/_DSC2853.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/kubo_rave/min/_DSC2959.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/kubo_rave/min/_DSC3052.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/kubo_rave/min/_DSC3077.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/kubo_rave/min/_DSC3135.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/kubo_rave/min/_DSC3128.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/kubo_rave/min/_DSC3107.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/kubo_rave/min/_DSC3153.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/kubo_rave/min/_DSC3202.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/kubo_rave/min/_DSC3223.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/kubo_rave/min/_DSC3260.jpg', possition: "gallery__image--2" },

        ]
    },
    {
        id: '8',
        title: 'LITTLE HIKE',
        subtitle: 'LITTLE HIKE',
        src: "",
        image: '/assets/images/vylet_jono_tabita/min/_DSC1837.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1620.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1643.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1647.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1660.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1676.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1679.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1681.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1709.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1719.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1767.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1796.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1806.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1820.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1852.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1905.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1929.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1933-2.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC1933.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2021.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2040.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2041.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2097.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2121.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2124.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/vylet_jono_tabita/min/_DSC2299.jpg', possition: "gallery__image--3" },

        ]
    },
    {
        id: '9',
        title: 'JUHASCIK FAMILY',
        subtitle: 'SAMO & ANICKA JUHASCIK FAMILY',
        src: "",
        image: '/assets/images/samo_anicka/min/_DSC2851.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/samo_anicka/min/_DSC1664.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC1690.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/samo_anicka/min/_DSC1786.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/samo_anicka/min/_DSC1886.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC1996.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/samo_anicka/min/_DSC2009.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/samo_anicka/min/_DSC2081.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2164.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2229.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2298.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2315.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2329.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/samo_anicka/min/_DSC2298.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2315.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2329.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/samo_anicka/min/_DSC2557.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2570.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2600.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/samo_anicka/min/_DSC2607.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2668.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2757.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/samo_anicka/min/_DSC2772.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/samo_anicka/min/_DSC2779.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2783.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2851.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC2912.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC2929.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/samo_anicka/min/_DSC2981.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/samo_anicka/min/_DSC3034.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/samo_anicka/min/_DSC3114.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC3276.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/samo_anicka/min/_DSC3378.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC3442.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC3466.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC3522.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/samo_anicka/min/_DSC3589.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC3661.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC3795.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/samo_anicka/min/_DSC3802.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/samo_anicka/min/_DSC3844.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/samo_anicka/min/_DSC3870.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/samo_anicka/min/_DSC3917.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/samo_anicka/min/_DSC3982.jpg', possition: "gallery__image--2" },
        ]
    },
    {
        id: '10',
        title: 'BERLIN TASTE VOL. 1',
        subtitle: 'BERLIN TASTE VOL. 1',
        src: "",
        image: '/assets/images/berlintaste/min/_DSC0873.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "13",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/berlintaste/min/_DSC0446.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/berlintaste/min/_DSC0488.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/berlintaste/min/_DSC0737.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/berlintaste/min/_DSC0994.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/berlintaste/min/_DSC1113.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/berlintaste/min/_DSC1304.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/berlintaste/min/_DSC1445.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/berlintaste/min/_DSC8001.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/berlintaste/min/_DSC8273.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/berlintaste/min/_DSC8509.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/berlintaste/min/_DSC9844.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/berlintaste/min/_DSC9982.jpg', possition: "gallery__image--1" },

        ]
    },
    {
        id: '11',
        title: 'ALLTIDE CONCERT IN STROMORADIE',
        subtitle: 'ALLTIDE CONCERT IN STROMORADIE',
        src: "",
        image: '/assets/images/alltide_stromoradie/min/_DSC4592.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "12",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/alltide_stromoradie/min/_DSC4361.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4427.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4564.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4576.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4583.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4592.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4597.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4632.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4636.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4689.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_stromoradie/min/_DSC4726.jpg', possition: "gallery__image--1" },

        ]
    },
    {
        id: '12',
        title: 'ALLTIDE MERCH',
        subtitle: 'ALLTIDE MERCH',
        src: "",
        image: '/assets/images/alltide_merch/min/_DSC0956.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "26",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/alltide_merch/min/_DSC9927.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_merch/min/_DSC9951.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC9991.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_merch/min/_DSC0185.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_merch/min/_DSC0247.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_merch/min/_DSC0403.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC0734.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_merch/min/_DSC0739.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_merch/min/_DSC1214.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC1301.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_merch/min/_DSC1509.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_merch/min/_DSC1564.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC1580.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_merch/min/_DSC1669.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_merch/min/_DSC1743.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC1778.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_merch/min/_DSC1855.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_merch/min/_DSC1880.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC1912.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_merch/min/_DSC1935.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC1951.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_merch/min/_DSC2057.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_merch/min/_DSC2278.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_merch/min/_DSC2297.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_merch/min/_DSC2413.jpg', possition: "gallery__image--1" },

        ]
    },
    {
        id: '13',
        title: 'HC KOSICE',
        subtitle: 'HC KOSICE',
        src: "",
        image: '/assets/images/hc_kosice/1.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "40",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/hc_kosice/1.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/2.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/3.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/4.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/5p.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/6.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/7p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/8p.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/hc_kosice/9p.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/10.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/11p.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/12p.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/13.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/14.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/hc_kosice/15p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/16.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/17p.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/18p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/19p.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/20p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/21p.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/22p.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/hc_kosice/23p.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/24p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/25p.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/26p.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/27p.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/28.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/29p.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/30.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/31p.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/hc_kosice/32p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/33.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/34p.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/35.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/hc_kosice/36p.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/hc_kosice/37.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/hc_kosice/38.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/hc_kosice/39.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/hc_kosice/40.jpg', possition: "gallery__image--2" },
        ]
    },
    {
        id: '14',
        title: 'SPDH KOSUTKA',
        subtitle: 'SPDH KOSUTKA',
        src: "",
        image: '/assets/images/spdh_kosutka/14.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "40",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/spdh_kosutka/1p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/3.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/4.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/5.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/6.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/7.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/8.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/spdh_kosutka/9.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/10p.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/11.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/12.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/13.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/15.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/16.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/17.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/2.jpg', possition: "gallery__image--2" },

            { path: '/assets/images/spdh_kosutka/18p.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/spdh_kosutka/19p.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/20.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/21.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/22.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/23.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/spdh_kosutka/24.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/25.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/26.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/27.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/28p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/29p.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/30.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/31.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/32.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/spdh_kosutka/33.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/spdh_kosutka/34.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/35.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/36.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/spdh_kosutka/37.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/spdh_kosutka/38p.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/spdh_kosutka/39.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/spdh_kosutka/40.jpg', possition: "gallery__image--1" },

        ]
    },
    {
        id: '15',
        title: 'ZILINA 2021 SAFBMX',
        subtitle: 'ZILINA 2021 SAFBMX',
        src: "",
        image: '/assets/images/zilina_street_2021/min/_DSC2609.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/zilina_street_2021/min/_DSC2701.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zilina_street_2021/min/_DSC2912.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/zilina_street_2021/min/_DSC2964.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/zilina_street_2021/min/_DSC3145.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/zilina_street_2021/min/_DSC3598.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/zilina_street_2021/min/_DSC3503.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/zilina_street_2021/min/_DSC3678.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/zilina_street_2021/min/_DSC3701.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/zilina_street_2021/min/_DSC7257.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zilina_street_2021/min/_DSC7527.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/zilina_street_2021/min/_DSC7621.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/zilina_street_2021/min/_DSC7631.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zilina_street_2021/min/_DSC7658.jpg', possition: "gallery__image--4" },

        ]
    },
    {
        id: '16',
        title: 'DIRTBIKE FUN',
        subtitle: 'DIRTBIKE FUN',
        src: "",
        image: '/assets/images/fun_with_dirtbikes/min/_DSC2587.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "13",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC1238.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC1271.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC1319.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC1362.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC1410.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2141.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2311.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2334.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2404.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2466.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2587.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2614.jpg', possition: "gallery__image--0" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2833.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2837.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2853.jpg', possition: "" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2858.jpg', possition: "gallery__image--0" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2995.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2996.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC2998.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4419.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4419-2.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4456.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4570.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4584.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4593.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC4613.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC5176-Edit.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC6632.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC6739.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC6942.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC7063.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC7143.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC7229.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/fun_with_dirtbikes/min/_DSC7256.jpg', possition: "gallery__image--4" },

        ]
    },

    {
        id: '17',
        title: 'ALLTIDE CAMPFEST',
        subtitle: 'ALLTIDE CAMPFEST',
        src: "",
        image: '/assets/images/alltide_cf/min/_DSC1370.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/alltide_cf/min/_DSC0934.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC0960.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_cf/min/_DSC0969.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_cf/min/_DSC1003.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_cf/min/_DSC1090.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_cf/min/_DSC1189.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/alltide_cf/min/_DSC1222.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC1274.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_cf/min/_DSC1300.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC1303-2.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_cf/min/_DSC1303.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC1337.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_cf/min/_DSC1356.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC1365.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_cf/min/_DSC1370.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_cf/min/_DSC1384.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC1427.jpg', possition: "gallery__image--0" },
            { path: '/assets/images/alltide_cf/min/_DSC1428.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/alltide_cf/min/_DSC1476.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/alltide_cf/min/_DSC1528.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/alltide_cf/min/_DSC1593.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/alltide_cf/min/_DSC1626.jpg', possition: "gallery__image--0" },
        ]
    },

    {
        id: '18',
        title: 'OPALCAMP PREPARATION',
        subtitle: 'OPALCAMP PREPARATION',
        src: "",
        image: '/assets/images/opal_priprava/min/_DSC0316.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/opal_priprava/min/_DSC0162.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_priprava/min/_DSC0182.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_priprava/min/_DSC0225.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_priprava/min/_DSC0241.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_priprava/min/_DSC0265.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_priprava/min/_DSC9477.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_priprava/min/_DSC0353.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_priprava/min/_DSC0406.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_priprava/min/_DSC9222.jpg', possition: "gallery__image--1" },
        ]
    },

    {
        id: '19',
        title: 'OPALCAMP',
        subtitle: 'OPALCAMP',
        src: "",
        image: '/assets/images/opal_camp/min/_75.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/opal_camp/min/_1.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_2.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_3.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_4.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_5.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_6.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_7.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_8.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_9.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_10.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_11.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_12.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_13.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_14.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_15.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_16.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_17.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_18.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_19.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_20.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_21.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_22.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_23.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_24.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_25.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_26.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_27.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_28.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_29.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_30.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_31.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_32.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_33.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_34.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_35.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_36.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_37.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_38.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_39.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_40.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_41.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_42.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_43.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_44.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_45.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_46.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_47.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_48.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_49.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_50.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_51.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_52.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_53.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_54.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_55.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_56.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_57.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_58.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_59.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_60.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_61.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_62.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_63.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_64.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_65.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_66.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_67.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_68.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_69.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_70.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_71.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_72.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_73.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_74.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_75.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_76.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_77.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_78.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_79.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_80.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_81.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_82.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_83.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_84.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_85.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_86.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_87.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_88.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_89.jpg', possition: "gallery__image--1" },

            { path: '/assets/images/opal_camp/min/_90.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_91.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/opal_camp/min/_92.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/opal_camp/min/_93.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_94.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/opal_camp/min/_95.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/opal_camp/min/_96.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/opal_camp/min/_97.jpg', possition: "gallery__image--1" },
        ]
    },

    {
        id: '20',
        title: 'MAREK KARLIK',
        subtitle: 'MAREK KARLIK',
        src: "",
        image: '/assets/images/marek/min/_DSC4634.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/marek/min/_DSC1598.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/marek/min/_DSC1606.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/marek/min/_DSC1622.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/marek/min/_DSC1730.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/marek/min/_DSC1749.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/marek/min/_DSC1787.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/marek/min/_DSC4351.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/marek/min/_DSC4361.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/marek/min/_DSC4505.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/marek/min/_DSC4543.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/marek/min/_DSC8568.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/marek/min/_DSC4553.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/marek/min/_DSC4565.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/marek/min/_DSC8113.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/marek/min/_DSC8119.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/marek/min/_DSC8154.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/marek/min/_DSC8208.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/marek/min/_DSC8329.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/marek/min/_DSC8351.jpg', possition: "gallery__image--2" },
        ]
    },


    {
        id: '21',
        title: 'SILVIA',
        subtitle: 'SILVIA',
        src: "",
        image: '/assets/images/silvia/min/_DSC7771.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/silvia/min/_DSC7875.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/silvia/min/_DSC8087.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/silvia/min/_DSC8135.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/silvia/min/_DSC8590.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/silvia/min/_DSC8411.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/silvia/min/_DSC8520.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/silvia/min/_DSC8608.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/silvia/min/_DSC8798.jpg', possition: "gallery__image--2" },
        ]
    },

    {
        id: '22',
        title: 'ZUZKA',
        subtitle: 'ZUZKA',
        src: "",
        image: '/assets/images/zuzka/min/_DSC4578.jpg',
        imageWidth: "1920",
        imageHeight: "1280",
        imagesCount: "14",
        date: '2022-05-03',
        desc:
            '',
        color: 'fff',
        pictures: [
            { path: '/assets/images/zuzka/min/_DSC4019.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/zuzka/min/_DSC4047.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zuzka/min/_DSC4174.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/zuzka/min/_DSC4213.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/zuzka/min/_DSC4234.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zuzka/min/_DSC4310.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/zuzka/min/_DSC3950.jpg', possition: "gallery__image--2" },
            { path: '/assets/images/zuzka/min/_DSC4531.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zuzka/min/_DSC4560.jpg', possition: "gallery__image--3" },
            { path: '/assets/images/zuzka/min/_DSC4578.jpg', possition: "gallery__image--4" },
            { path: '/assets/images/zuzka/min/_DSC4703.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zuzka/min/_DSC4751.jpg', possition: "gallery__image--5" },
            { path: '/assets/images/zuzka/min/_DSC4846.jpg', possition: "gallery__image--1" },
            { path: '/assets/images/zuzka/min/_DSC4909.jpg', possition: "gallery__image--4" },
        ]
    },
];

export default data;


