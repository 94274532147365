import React from "react"
import { useEffect } from 'react';
// import { Link } from "react-router-dom"
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

//projects import
import ProjectInMenu from '../components/ProjectInMenu';
import data from '../data/menu_projects';

export default function Home() {

  // home page-template-default page page-id-2 page-startseite
  useEffect(() => {
    document.body.classList.add('home');
    document.body.classList.add('page-template-default');
    document.body.classList.add('page');
    document.body.classList.add('page-id-2');
    document.body.classList.add('page-startseite');

    return () => {
      // document.body.classList.remove('home');
      // document.body.classList.remove('page-template-default');
      // document.body.classList.remove('page');
      // document.body.classList.remove('page-id-2');
      // document.body.classList.remove('page-startseite');
    };
  }, []);

  return (
    <React.Fragment>
      <Navbar />

      <article className="site__main" id="main-wrapper">
        <Helmet>
          <script>   document.body.style.background = '#ffffff'; url(../images/resources/texture_gray.png) </script>
        </Helmet>
        <section className="works__section">
          <div className="works__inner">

            {data.map((menuprojects) => (
              <ProjectInMenu
                menu_projects={menuprojects}
                key={menuprojects.title}
              />
            ))}

          </div>
        </section>
      </article>

      <Footer />
    </React.Fragment>
  );
}




