import React from "react"
import { useEffect } from 'react';
// import { Link } from "react-router-dom"
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PictureInContact from "../components/PictureInContact";
import PictureInProject from "../components/PictureInProject";
import SlidingText from "../components/SlidingText";

import contact_images from '../data/contact_images';

//projects import

export default function About() {

  return (
    <React.Fragment>
      <Navbar />
      <div className="contact__body">
        <article className="site__main" id="main-wrapper">
          <section className="about__container">
            <div className="about__inner">
              <div className="wp-block-columns">
                {/* <div className="wp-block-column" style="flex-basis:66.66%"> */}
                <div className="wp-block-column">
                  <p className="has-text-align-center p1">
                    Tu este vymyslim nieco o tom ako velmi mi zalezi na zachytenom momente a nejaky podobny BS. heh{" "}
                  </p>

                  {/* <p className="has-text-align-center p1">
                    <a
                      href="/"
                      className="moments_link"
                    >
                      {" "}
                      work
                    </a>
                    .
                  </p> */}

                  <div className="black__image margin hidden-mobile"></div>

                  <div className="contact__container hidden-mobile">
                    {/* <pre className="wp-block-preformatted"><span className="title uppercase name">PHOTOGRAPHER</span> */}
                    <pre className="wp-block-preformatted">
                      <span className="title uppercase name">
                        PHOTOGRAPHER
                      </span>

                      <br />
                      <br />
                      <br />

                      <div className="contact__inner">
                        <div className="contact">
                          <span className="title uppercase">
                            Based in Slovakia
                          </span>
                          <span>
                            <a
                              href="mailto:kubek.breha@gmail.sk"
                              className="italic"
                            >
                              contact jakubbrehuv.sk
                            </a>
                          </span>
                        </div>
                        <br />
                        <br />

                        <br />
                        <a href="tel:+421 902 130 280" className="italic tel">
                          +421 902 130 280
                        </a>
                        <br />
                        <br />

                        <div className="contact sns">
                          <span className="title uppercase">INSTAGRAM</span>
                          <a
                            href="https://www.instagram.com/jakubbrehuv/"
                            className="italic"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            @jakubbrehuv
                          </a>
                        </div>
                        <br />
                        <br />

                        <div className="contact sns">
                          <span className="title uppercase">BEHANCE</span>
                          <a
                            href="https://www.behance.net/jakubbrehuv"
                            className="italic"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            jakubbrehuv
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact__container show-mobile-about-page">
              <div className="inner">
                <div className="black__image margin"></div>

                <span className="title uppercase name">PHOTOGRAPHER</span>

                <div className="contact__inner">
                  <div className="contact">
                    <span className="title uppercase">Based in Slovakia</span>
                    <span>
                      <a
                        href="mailto:kubek.breha@gmail.sk"
                        className="italic"
                      >
                        contact jakubbrehuv.sk
                      </a>
                    </span>
                  </div>

                  <a href="tel:+421 902 130 280" className="italic tel">
                    +421 902 130 280
                  </a>

                  <div className="contact sns">
                    <span className="title uppercase">INSTAGRAM</span>
                    <a
                      href="https://www.instagram.com/jakubbrehuv/"
                      className="italic"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      @jakubbrehuv
                    </a>
                  </div>
                  <div className="contact sns">
                    <span className="title uppercase">BEHANCE</span>
                    <a
                      href="https://www.behance.net/jakubbrehuv"
                      className="italic"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      jakubbrehuv
                    </a>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>

          <section className="gallery" id="scroll__area">
            {contact_images.map((pic) => (
              <PictureInContact picture={pic} />
            ))}
          </section>
        </article>
      </div>
      <Footer />
    </React.Fragment>
  );
}




