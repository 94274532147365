import * as React from 'react';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import useGaTracker from './useGaTracker'

import RouterPage from './pages/RouterPage'

function App() {
  useGaTracker();

  return (
    // <div className='links__image'>
    <div className='App'>
      <ScrollToTop />
      <RouterPage />
    </div>
    // </div>
  );
}

export default App;


