import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import SlidingText from './SlidingText';
import MiniProject from './MiniProject';
import projectData from '../data/menu_projects';

// import dayjs from 'dayjs';

var project = Math.floor(Math.random() * projectData.length - 3) + 1;
const ProjectRecomendation = ({ data }) => (

    <section className="project__footer marquee end" id="scroll__area">

        <SlidingText text={'MORE'} />
        <div className="more__projects ">

            {projectData.slice(project, project + 3).map((menuprojects) => (
                <MiniProject
                    menu_projects={menuprojects}
                />
            ))}

        </div>
    </section>


);


export default ProjectRecomendation;
