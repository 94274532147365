import React from 'react'
import { Helmet } from 'react-helmet'
import SlidingText from '../components/SlidingText'
import Image from "../bg.jpg"
import { useEffect } from 'react';

export default function Links() {
  useEffect(() => {
    document.body.style.backgroundImage = `url('${Image}')`;
    document.body.style.backgroundPosition = "center center";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";

    // document.body.style.left = "50%";
    // document.body.style.right = "50%";
    // document.body.style.marginLeft = "-32px";
    // document.body.style.marginTop = "-32px";
  }, []);

  return (
    <React.Fragment>

      {/* <div style={divStyle}> */}
      <div className='div_links_center'>
        <section className="about__container ">
          <div className="about__inner ">
            <div className="wp-block-columns ">
              {/* <div className="wp-block-column" style="flex-basis:66.66%"> */}
              <div className="wp-block-column">
                <div className="black__image margin hidden-mobile"></div>

                <div className="contact__container hidden-mobile">
                  {/* <pre className="wp-block-preformatted"><span className="title uppercase name">PHOTOGRAPHER</span> */}
                  <pre className="wp-block-preformatted">
                    <br />



                    <div className="contact__inner ">
                      <a
                        href="https://tootoot.fm/sk/events/62f16fdd7894c3043854ef6a"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        LISTKY na "V MOJEJ HLAVE"
                      </a>
                    </div>

                    <br>
                    </br>
                    <br>
                    </br>
                    <br>
                    </br>
                    <br>
                    </br>


                    <div className="contact__inner ">
                      <a
                        href="https://www.jakubbrehuv.sk/"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        WEB
                      </a>

                      <a
                        href="https://www.instagram.com/jakubbrehuv/"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        INSTAGRAM
                      </a>

                      <a
                        href="https://www.behance.net/jakubbrehuv"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        BEHANCE
                      </a>

                      <a
                        href="http://open.spotify.com/user/11173513653"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        SPOTIFY
                      </a>

                      <a
                        href="https://sk.pinterest.com/kbrehuv/"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        PINTEREST
                      </a>

                      <a
                        href="http://bere.al/jakubbrehuv"
                        className="title uppercase padding_links"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        BE REAL
                      </a>



                    </div>




                  </pre>
                </div>
              </div>
            </div>
          </div>



          <div className="contact__container show-mobile-about-page">
            <div className="inner">
              <div className="black__image margin"></div>

              <div className="contact__inner">
                <div className="contact">
                  <a
                    href="https://tootoot.fm/sk/events/62f16fdd7894c3043854ef6a"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    LISTKY na "V MOJEJ HLAVE"
                  </a>
                </div>
              </div>

              <br></br>
              <br></br>

              <div className="contact__inner">
                <div className="contact">
                  <a
                    href="https://www.jakubbrehuv.sk/"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    WEB
                  </a>
                </div>
                <div className="contact">
                  <a
                    href="https://www.instagram.com/jakubbrehuv/"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    INSTAGRAM
                  </a>
                </div>
                <div className="contact">
                  <a
                    href="https://www.behance.net/jakubbrehuv"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    BEHANCE
                  </a>
                </div>



                <div className="contact">
                  <a
                    href="http://open.spotify.com/user/11173513653"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    SPOTIFY
                  </a>


                </div>
                <div className="contact">
                  <a
                    href="https://sk.pinterest.com/kbrehuv/"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    PINTEREST
                  </a>


                </div>
                <div className="contact">
                  <a
                    href="http://bere.al/jakubbrehuv"
                    className="title uppercase padding_links"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    BE REAL
                  </a>
                </div>


              </div>
            </div>
          </div>
        </section >
      </div>



    </React.Fragment >
  )
}
