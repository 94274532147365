import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from './Home'
import Film from './Film'
import Random from './Random'
import NotFound from './NotFound'
import Project from './Project'
import Contact from './Contact'
import Links from './Links'
import DataProtection from './DataProtection'



export default function RouterPage() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/links" element={<Links />} />
            <Route path="/film" element={<Film />} />
            <Route path="/random" element={<Random />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/data-protection" element={<DataProtection />} />
            {/* todo limit projects in list for roouter */}
            <Route path="/project/:id" element={<Project />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

// TODOS
// - [ ]  path vsetkeho z jakubbbrehuv.sk
// - [ ]  coookies
// - [ ]  contact page
// - [ ]  co ked kliknem na fotku
// - [ ]  random page
// - [ ]  navbar transparent


// https://www.npmjs.com/package/react-lazy-load-image-component  - done
// https://github.com/aFarkas/lazysizes - probably not neaded

// https://stackoverflow.com/questions/62335443/react-links-requested-url-was-not-found-on-this-server-after-being-deployed